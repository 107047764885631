/*
import React, { useState } from 'react';
import { Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import LoginPage from './LoginPage';
import Chat from './Chat';
import UserProfile from './UserProfile';
import UserProvider from './UserProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
*/

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import UserProvider from './UserProvider';
import UserProfile from './UserProfile';
import Chat from './Chat';
import LoginPage from './LoginPage';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const theme = {
    ...grommet,
    global: {
      font: {
        family: 'Arial, sans-serif',
      },
      colors: {
        brand: '#00008B', // Change this to the blue color you want for the header
      },
    },
  };

  return (
    <Grommet theme={theme} full>
      <Router>
        <UserProvider>
          <Routes>
            <Route path="/userprofile" element={isLoggedIn ? <UserProfile /> : <LoginPage onLogin={handleLogin} />} />
            <Route path="/" element={isLoggedIn ? <Chat /> : <LoginPage onLogin={handleLogin} />} />            
          </Routes>
        </UserProvider>

      </Router>
    </Grommet>
  );


};

export default App;
