import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Heading, Header, TextInput, Text, Select } from 'grommet';
import { Image } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { fetchApiAnswer } from './apiClaude.js';

import './Chat.css'; 
import avatars from './avatar/avatars.json'; 
import preferredAvatarsSize from './user/preferred-avatar-size.json';

import logoImage from './icon/ai-chat.png'
import copyIcon from './icon/copy-black.png';
import speakIcon from './icon/speak-black.png';
import sendIcon from './icon/send-blue.png';
import settingsIcon from './icon/settings-blue.png';
import avatarSizeIcon from './icon/avatar-size.png';
import logoutIcon from './icon/logout-blue.png';

import UsernameContext from './UsernameContext';

const chatBoxFontSize = 'medium';
const chatBoxFontFamily = 'Arial';
const chatBoxLineHeight = '2';
let allowedSelectOptions = [0, 1, 2, 3];

const Chat = () => {
  const { username } = useContext(UsernameContext);
  const location = useLocation();

  const [user, setUser] = useState({
    username: '',
    name: '',
    email: '',
    gender: '',
    groups: [],
    permissionLevel: '',
    ownedAvatars: [],    
    selectedAvatar: '',
    secretaryAvatar: '',
    preferredTheme: '',
    preferredBackground: '',
    preferredAvatarSize: '',
    preferredLanguage: '',
    preferredAIModel: ''
  });

  const fetchUser = () => {     
    fetch(`https://chat.algugu.com:52012/api/profile?username=${encodeURIComponent(username)}`)
      .then(response => response.json())
      .then(data => {
        setUser(data);        
        setDefaultScreenMode();         
      })
      .catch(error => console.error('Error:', error));
  };  

  const setDefaultScreenMode = () => {


    const { innerWidth, innerHeight } = window;
    
    if (innerWidth >= 1366 && innerHeight >= 768) { // Large desktop screen
      setScreenMode(2);
      allowedSelectOptions = [0, 1, 2, 3]
    } else if (innerWidth >= 1024 && innerHeight >= 576) { // Normal desktop screen
      setScreenMode(2);
      allowedSelectOptions = [0, 1, 2, 3]
    } else if (innerWidth >= 800 ) { // Small desktop screen
      setScreenMode(1);
      allowedSelectOptions = [0, 1, 2]
    } else if (innerWidth <= 600) { // Mobile screen      
      setScreenMode(0);
      allowedSelectOptions = [0, 1]
    }
    setSelectOptions(allowedSelectOptions);         
    const names = allowedSelectOptions.map(id => {
      const size = preferredAvatarsSize['preferred-avatar-size'].find(size => size.id === id);
      return size ? size.name['zh-TW'] : null;
    });        
    setSelectOptions(names);     
    
  };  

  useEffect(() => {
    fetchUser();
  }, [username, location]);  // 添加 location 作為依賴  

  //console.log(user);

  //const myImagePath = user.selectedAvatar ? avatars.avatars.find(avatar => avatar.id === user.selectedAvatar).path : null;  
  //const secImagePath = user.secretaryAvatar ? avatars.avatars.find(avatar => avatar.id === user.secretaryAvatar).path : null;      
  const myAvatar = avatars.avatars.find(avatar => avatar.id === user.selectedAvatar);
  const myImagePath = myAvatar ? myAvatar.path : null;

  const secAvatar = avatars.avatars.find(avatar => avatar.id === user.secretaryAvatar);
  const secImagePath = secAvatar ? secAvatar.path : null;
  const secGender = secAvatar ? secAvatar.gender : null;
  const nickname = secAvatar ? secAvatar.name['zh-TW'] : null;

  // 暫時Random問題未解決, 先用第一個opening mssage
  //const welcomeMessage = secAvatar ? secAvatar.opening_messages['zh-TW'][Math.floor(Math.random() * secAvatar.opening_messages['zh-TW'].length)] : null;
  const welcomeMessage = secAvatar ? secAvatar.opening_messages['zh-TW'][0] : null;




//  const secGender = user.secretaryAvatar ? avatars.avatars.find(avatar => avatar.id === user.secretaryAvatar).gender : null;
//  const nickname = user.secretaryAvatar ? avatars.avatars.find(avatar => avatar.id === user.secretaryAvatar).name['zh-TW'] : null;;
//  const welcomeMessage = user.secretaryAvatar ? avatars.avatars.find(avatar => avatar.id === user.secretaryAvatar).opening_messages['zh-TW'][Math.floor(Math.random() * avatars.avatars.find(avatar => avatar.id === user.secretaryAvatar).opening_messages['zh-TW'].length)]: null;






  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false); // 新增 loading 狀態
  //const [welcomeMessage, setWelcomeMessage] = useState(secAvatar ? secAvatar.opening_messages['zh-TW'][Math.floor(Math.random() * secAvatar.opening_messages['zh-TW'].length)] : null);

  const navigate = useNavigate();
  
  const chatBoxRef = useRef();
  const inputRef = useRef();

  const questionBoxRef = useRef();
  const answerBoxRef = useRef();
  const questionTextBoxRef = useRef();
  const answerTextBoxRef = useRef();
  const questionImageBoxRef = useRef();
  const answerImageBoxRef = useRef();

  const [screenMode, setScreenMode] = useState(0);
  const [selectOptions, setSelectOptions] = useState([]);
  
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  function handleLogout() {
    // 清除所有本地存儲的值
    localStorage.clear();

    // 導向登錄頁面（根據您的路由設定可能需要調整）
    window.location.href = '/';
}  

  /*
  const handleSpeak = (text, pitch) => {
    let msg = new SpeechSynthesisUtterance();
    let voices = window.speechSynthesis.getVoices();
    // 檢查文本是否包含中文字符
    if (/[\u4e00-\u9fa5]/.test(text)) {
      // 如果文本包含中文字符，則使用粵語讀出
      msg.voice = voices.filter(voice => voice.lang === 'zh-HK')[0];
      msg.lang = 'zh-HK';
      msg.rate = 0.8; // 0.1 to 10
      msg.text = text + '，，'; // 在中文後面添加長停頓
    } else {
      // 如果文本不包含中文字符，則使用英語讀出
      msg.voice = voices.filter(voice => voice.lang === 'en-US')[0];
      msg.lang = 'en-US';
      msg.rate = 0.6; // 0.1 to 10
      msg.text = text + '..'; // 在英文後面添加長停頓
    }
    msg.volume = 1;  // 0 to 1
    msg.rate = 0.8;    // 0.1 to 10
    msg.pitch = pitch; // 0 to 2, 1.5 means "moderate female"
    window.speechSynthesis.speak(msg);
  };
  */

  
  const handleSpeak = (text, gender) => {
    let msg = new SpeechSynthesisUtterance();
    let voices = window.speechSynthesis.getVoices();
    msg.voice = voices.filter(voice => voice.lang === 'zh-HK')[0];
    msg.lang = 'zh-HK';    
    msg.volume = 0.8;  // 0 to 1
    msg.rate = 0.8;    // 0.1 to 10

    if (gender === 1) {
      msg.pitch = 0; // 0 to 2, 1.5 means "moderate female"
    } else {
      msg.pitch = 1; // 0 to 2, 1.5 means "moderate female"
    }

    msg.text = text;
    window.speechSynthesis.speak(msg);
  };
    
  const handleSettings = (event) => {
    navigate('./userprofile');
  };  
  
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === '') {
      return;
    }

    setLoading(true); // Set loading to true when sending a message
    setChatHistory(prevChatHistory => [...prevChatHistory, { user: userInput, bot: '', loading: true }]);
    const apiResponse = await fetchApiAnswer(userInput, nickname);
    setChatHistory(prevChatHistory => prevChatHistory.map((message, index) =>
      index === prevChatHistory.length - 1 ? { ...message, bot: apiResponse, loading: false } : message
    ));
    setUserInput('');
    setLoading(false); // Set loading to false after receiving the response
    inputRef.current.focus();    
  };


  useEffect(() => {

    // Call the function once to set the initial state
    setDefaultScreenMode();
     
    // Add the event listener
    window.addEventListener('resize', setDefaultScreenMode);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', setDefaultScreenMode);
  }, []);
  
  const calChatBoxImageSize_Huge = () => {
    const windowsHeight = window.innerHeight - 280 - 15;
    const windowsWidth = window.innerWidth - 15;
    return Math.min(windowsHeight, windowsWidth); 
  }
  
  const calChatBoxImageSize_Large = () => {
    const windowsHeight = (window.innerHeight - 280) / 2 - 15;
    const windowsWidth = (window.innerWidth) / 2 - 15
    return Math.min(windowsHeight, windowsWidth); 
  }
  

  let chatBoxImageSize;
  if (screenMode === 3) {  
    chatBoxImageSize = calChatBoxImageSize_Huge() + 'px';    
  } else if (screenMode === 2) {
    chatBoxImageSize = calChatBoxImageSize_Large() + 'px';    
  } else if (screenMode === 1) {
    chatBoxImageSize = '150px';
  } else if (screenMode === 0) {
    chatBoxImageSize = '80px';
  } else {
    chatBoxImageSize = '200px';
  }  
  
  useEffect(() => {

    // 更新所有聊天記錄的高度
    const updateChatHeight = () => {
      // 獲取所有的問題和答案框
      const questionBoxes = document.querySelectorAll('#questionMainBox');
      const answerBoxes = document.querySelectorAll('#answerMainBox');
      const welcomeBoxes = document.querySelectorAll('#welcomeMainBox');
  
      // 更新每一個問題和答案框的 minHeight
      welcomeBoxes.forEach((box) => {
        const textBox = box.querySelector('#welcomeTextBox');
        const imageBox = box.querySelector('#welcomeImageBox');
        const textHeight = textBox ? textBox.offsetHeight : 0;
        const imageHeight = imageBox ? imageBox.offsetHeight : 0;
        box.style.minHeight = `${Math.max(textHeight, imageHeight)}px`;
      });
      
      questionBoxes.forEach((box) => {
        const textBox = box.querySelector('#questionTextBox');
        const imageBox = box.querySelector('#questionImageBox');
        const textHeight = textBox ? textBox.offsetHeight : 0;
        const imageHeight = imageBox ? imageBox.offsetHeight : 0;
        box.style.minHeight = `${Math.max(textHeight, imageHeight)}px`;
      });
      
      answerBoxes.forEach((box) => {
        const textBox = box.querySelector('#answerTextBox');
        const imageBox = box.querySelector('#answerImageBox');
        const textHeight = textBox ? textBox.offsetHeight : 0;
        const imageHeight = imageBox ? imageBox.offsetHeight : 0;
        box.style.minHeight = `${Math.max(textHeight, imageHeight)}px`;
      });
      
    };
  
    // 創建一個新的 MutationObserver 實例來監聽變化
    const observer = new MutationObserver(updateChatHeight);
  
    // 開始監聽 chatBoxRef 的子節點變化
    observer.observe(chatBoxRef.current, { childList: true });
  
    // 當滾動時，更新高度
    chatBoxRef.current.addEventListener('scroll', updateChatHeight);
  
    // 新增：當窗口大小變化時，更新高度
    window.addEventListener('resize', updateChatHeight);
  
    setTimeout(() => {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight; // 滾動到底部
    }, 0);
    updateChatHeight(); // 更新高度
      
    // 在組件卸載時停止監聽
    return () => {
      observer.disconnect();
      if (chatBoxRef.current) {
        chatBoxRef.current.removeEventListener('scroll', updateChatHeight);
      }
      window.removeEventListener('resize', updateChatHeight);
    };
    
  }, [chatHistory, screenMode]); // 當chatHistory或screenMode變化時更新minHeight

  return (    
    <Box className="chat-container">
      <Header background="brand" pad="large" height="100px">
        <Box direction="row" align="center" justify="between" fill="horizontal">
          <Heading level={3} margin="none">          
            <Image id="logoImage" className="logo-image" src={logoImage} />                
          </Heading>            
          <Box direction="row" align="center" gap="small">
            <Box>
              {/*<Image id="avatarSizeImage" className="chat-heading-image" src={avatarSizeIcon} />*/}                            
            </Box>
            <Select
              options={selectOptions}               
              value={selectOptions[screenMode]}
              onChange={({ option }) => setScreenMode(selectOptions.indexOf(option))}
              width="90px"              
            />
            <Button id="settingsButton" className="chat-heading-button"
              icon={<Image id="settingsButtonImage" className="chat-heading-image" src={settingsIcon} onClick={handleSettings}/>}               
            />
            <Button id="logoutButton" className="chat-heading-button"
              icon={<Image id="logoutButtonImage" className="chat-heading-image" src={logoutIcon} onClick={handleLogout}/>}               
            />
          </Box>
        </Box>
      </Header>
      <Box ref={chatBoxRef} className="chat-box">
        <Box id="welcomeMainBox" ref={answerBoxRef} direction="row" align="center" gap="small">
          <Image id="welcomeImageBox" ref={answerImageBoxRef} src={secImagePath} className="chat-avatar" style={{width: `${chatBoxImageSize}`, height: `${chatBoxImageSize}`}}/>
          <Box id="welcomeTextBox" ref={answerTextBoxRef} className="chat-message" pad="large" round="small" background="light-3" style={{marginRight: 'auto'}}>
            <Text size={chatBoxFontSize} style={{fontFamily: `${chatBoxFontFamily}`, lineHeight: `${chatBoxLineHeight}`}}>{welcomeMessage}</Text>            
          </Box>          
        </Box>  
        <Box pad="medium" round="small"></Box>      
        {chatHistory.map((message, index) => (
          <React.Fragment key={index}>
            
            <Box id="questionMainBox" ref={questionBoxRef} direction="row" align="center" gap="small">
              <Box id="questionTextBox" ref={questionTextBoxRef} className="chat-message" pad="large" round="small" background="light-1" style={{marginLeft: 'auto'}}>
                <Text size={chatBoxFontSize} style={{fontFamily: `${chatBoxFontFamily}`, lineHeight: `${chatBoxLineHeight}`}}>{message.user}</Text>                
                <Box className="chat-copyIcon" onClick={() => handleCopy(message.user)} style={{backgroundImage: `url(${copyIcon})`}} />                
              </Box>
              {myImagePath && (<Image id="questionImageBox" ref={questionImageBoxRef} src={myImagePath} className="chat-avatar" style={{width: `${chatBoxImageSize}`, height: `${chatBoxImageSize}`}}/>)}
            </Box>
            <Box pad="medium" round="small"></Box>
            {!message.loading && (
              <Box id="answerMainBox" ref={answerBoxRef} direction="row" align="center" gap="small">
                {secImagePath && (<Image id="answerImageBox" ref={answerImageBoxRef} src={secImagePath} className="chat-avatar" style={{width: `${chatBoxImageSize}`, height: `${chatBoxImageSize}`}}/>)}
                <Box id="answerTextBox" ref={answerTextBoxRef} className="chat-message" pad="large" round="small" background="light-3" style={{marginRight: 'auto'}}>
                  <Text size={chatBoxFontSize} style={{fontFamily: `${chatBoxFontFamily}`, lineHeight: `${chatBoxLineHeight}`}}>{message.bot}</Text>
                  {secGender && (<Box className="chat-speakIcon" onClick={() => handleSpeak(message.bot, secGender)} style={{backgroundImage: `url(${speakIcon})`}} />)}
                  <Box className="chat-copyIcon" onClick={() => handleCopy(message.bot)} style={{backgroundImage: `url(${copyIcon})`}} />
                </Box>
              </Box>
            )}
            <Box pad="medium" round="small"></Box>
          </React.Fragment>
        ))}
      </Box>
      <Box pad="large" background="light-2" className="chat-input" height="80px">
        <Box direction="row" align="center" gap="small" style={{alignItems: "center"}}>
          <TextInput
            ref={inputRef}
            placeholder="輸入您的問題（1500字內）"
            value={userInput}
            onChange={handleInputChange}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSendMessage();
              }
            }}
            disabled={loading} // Disable the input when loading
            style={{flexGrow: 1}} // TextInput takes up the remaining space
            maxLength={1500} // Add this line to limit input to 1500 characters
          />
          <Button id="sendButton" className="chat-inputbox-button"
            icon={<Image id="sendButtonImage" className="chat-inputbox-button-image" src={sendIcon} />} // 調整這裡的寬度和高度
            onClick={handleSendMessage}
            disabled={loading} // Disable the button when loading            
          />          
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;

