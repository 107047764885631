import React from 'react';

// 創建一個Context
const UsernameContext = React.createContext();

// 創建一個Provider組件
export const UsernameProvider = ({ children }) => {
  const [username, setUsername] = React.useState('');

  // 將username和setUsername都傳遞給Provider
  return (
    <UsernameContext.Provider value={{ username, setUsername }}>
      {children}
    </UsernameContext.Provider>
  );
};

export default UsernameContext;
