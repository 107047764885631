import React, { useState, useRef, useEffect } from 'react';

export async function fetchApiAnswer(message, nickname) {
    const SECRET_TOKEN = 'yCYubwVQWGAZYJJbmDssF65ZH4lE2TWuPEbH6QUQ_eI'; // 替換為你的實際 token
  
    try {
      const response = await fetch('https://chat.algugu.com:52013/api', {
      //const response = await fetch('http://192.168.0.51:52013/api', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${SECRET_TOKEN}`, // 注意這裡的格式
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, nickname }),
      });
  
      if (!response.ok) {
        throw new Error('API 請求失敗');
      }
  
      const data = await response.json();
      console.log(data); // 打印获取到的数据  
      return data.result;
    } catch (error) {
      console.error(error); // 打印错误信息
      return '不好意思，遇到技術問題，請稍後再試。（API 請求失敗）'; // 返回友好的错误信息
    }
  }

  export const ApiClaudeAnswer = ({ message }) => {
    const [apiData, setApiData] = useState('');
  
    useEffect(() => {
      fetchApiAnswer(message).then(result => {
        setApiData(result);
      });
    }, [message]); // 監聽 message 的變化
  
    return (
      <div>
        {/* 直接渲染從 API 獲取的數據 */}
        {apiData}
      </div>
    );
  };