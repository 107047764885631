// LoginPage.js
import React, { useState, useContext } from 'react';
import { Box, Form, Image, FormField, Button } from 'grommet';
import UsernameContext from './UsernameContext';

import logoImage from './icon/ai-chat.png'

const LoginPage = ({ onLogin }) => {
  const { setUsername } = useContext(UsernameContext);
  const [user, setUser] = useState({ username: '', password: '' });

  const handleSubmit = () => {
    fetch('https://chat.algugu.com:52012/api/login', {    
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
    .catch(error => {
      console.error('Network error:', error);
      alert('網絡錯誤，請檢查您的連接並重試。');
      return Promise.reject(error);
    })
    .then(response => {
      if (response && !response.ok) {
        if (response.status === 400) {
          throw new Error('無效的用戶名或密碼');
        } else {
          throw new Error('網絡響應不是ok');
        }
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        setUsername(user.username); // 在登錄成功後更新全局的username狀態
        onLogin();
      } else {
        alert('帳號或密碼錯誤，請重試。');
      }
    })
    .catch(error => {
      if (error.message !== '網絡錯誤，請檢查您的連接並重試。') {
        console.error('Error:', error);
        alert(error.message);
      }
    });
  };
  
  
    
  
  

  return (
    <Box align="center" pad="medium">
      <Image id="logoImage" src={logoImage} style={{height: '120px', width: '120px', padding: '50px'}} /> 
      <Form
        value={user}
        onChange={setUser}
        onSubmit={handleSubmit}
      >
        <FormField name="username" label="用戶名" required />
        <FormField name="password" label="密碼" required type="password" />
        <Box pad="small" direction="row" justify="center" margin={{ top: 'medium' }}>
          <Button type="submit" primary label="登入" style={{height: '50px', width: '120px'}}/>
        </Box>

      </Form>
    </Box>
  );
};

export default LoginPage;
