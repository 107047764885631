import React, { useState, useEffect, useContext } from 'react';
import { Box, Form, Image, Text, FormField, Button, Select, Heading, Grid, Collapsible } from 'grommet';
import UsernameContext from './UsernameContext';

import { useNavigate } from 'react-router-dom';

import settingsIcon from './icon/settings-blue.png';

import avatarCategories from './avatar/avatar-category.json'; // 引入類別數據
import gender from './user/gender.json'; // 引入權限等級數據
import permissionLevels from './user/permission-level.json'; // 引入權限等級數據
import preferredAatarSize from './user/preferred-avatar-size.json'; // 引入權限等級數據
import preferredTheme from './user/preferred-theme.json'; // 引入權限等級數據
import preferredBackground from './user/preferred-background.json'; // 引入權限等級數據
import preferredLanguage from './user/preferred-language.json'; // 引入權限等級數據
import preferredAiModel from './user/preferred-ai-model.json'; // 引入權限等級數據


import AvatarSelector from './AvatarSelector';

const ProfilePage = () => {
  const { username } = useContext(UsernameContext);  
  const navigate = useNavigate();
  const [showAvatars, setShowAvatars] = useState(false);
  const [message, setMessage] = useState(null);

  //const [user, setUser] = useState(null);

  
  const [user, setUser] = useState({
    username: '',
    name: '',
    email: '',
    gender: '',
    groups: [],
    permissionLevel: '',
    ownedAvatars: [],    
    selectedAvatar: '',
    secretaryAvatar: '',
    preferredTheme: '',
    preferredBackground: '',
    preferredAvatarSize: '',
    preferredLanguage: '',
    preferredAIModel: ''
  });   

  const fetchUser = () => {     
    fetch(`https://chat.algugu.com:52012/api/profile?username=${encodeURIComponent(username)}`)
      .then(response => response.json())
      .then(data => setUser(data))            
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchUser();
  }, [username]);  

  const userPermissionLevel = user ? user.permissionLevel : null;
  const ownedAvatars = user ? user.ownedAvatars : [];    

  //console.log(user.ownedAvatars);

  // 假設你已經有了用戶的權限等級
  //const userPermissionLevel = 1; // 例如，用戶是VIP
  //const ownedAvatars = [1, 2, 3, 4, 5, 6, 7, 8]; // 用戶所持有的頭像編號數組

  // 獲取用戶的權限等級對象
  const userPermission = user && permissionLevels['permission-level'].find(level => level.id === user.permissionLevel);

  // 獲取用戶允許的頭像類別
  const allowedCategories = userPermission && avatarCategories['avatar-category'].filter(category => userPermission['allowed-avatar-category'].includes(category.id));




  //const userPermissionLevel = user ? user.permissionLevel : null;
  //const ownedAvatars = user ? user.ownedAvatars : [];  

  //const userPermissionLevel = 1; // 例如，用戶是VIP
  //const ownedAvatars = [1, 2, 3, 4, 5, 6, 7, 8]; // 用戶所持有的頭像編號數組  

  const handleSubmit = () => {
    fetch(`https://chat.algugu.com:52012/api/profile?username=${encodeURIComponent(user.username)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        fetchUser();
        setMessage('保存成功！');
        setTimeout(() => setMessage(null), 3000); // 3秒後消失
      })
      .catch(error => {
        console.error('Error:', error);
        setMessage('保存失敗，請重試。');
        setTimeout(() => setMessage(null), 3000); // 3秒後消失
      });
  };

  const handleCancel = () => {
    navigate(-1); // 返回之前的頁面
  };

  return (
    <Box align="center" pad="medium">      
      <Heading level="2">        
        <Image id="settingsButtonImage" src={settingsIcon} style={{ height: '28px', width: '28px', paddingRight: '10px'}} />
        <Text style={{ fontSize: '32px' }}>用戶設置</Text>
      </Heading>
      <Form
        value={user}
        onChange={setUser}
        onSubmit={handleSubmit}
        style={{width: 'calc(100%/3)', minWidth: '300px'}}
      >
        <FormField name="name" label="姓名" Size="large" required />
        <Box pad="medium"></Box>
        <FormField name="email" label="電郵地址" required type="email" />
        <Box pad="medium"></Box>
        <FormField name="gender" label="性別" required>
          <Select
            options={gender.gender.map(option => option.name['zh-TW'])}
            value={gender.gender.find(option => option.id === user.gender)?.name['zh-TW']}
            onChange={({ option }) => setUser({ ...user, gender: gender.gender.find(opt => opt.name['zh-TW'] === option).id })}
          />
        </FormField>
        <Box pad="medium"></Box>
        {userPermission && ownedAvatars && (
          <FormField name="selectedAvatar" label="你的頭像">
            <AvatarSelector
              selectedAvatar={user.selectedAvatar}
              setSelectedAvatar={(id) => setUser({ ...user, selectedAvatar: id })}
              ownedAvatars={ownedAvatars}
              userPermissionLevel={userPermissionLevel}
            />
          </FormField>
        )}
        {userPermission && ownedAvatars && (
          <FormField name="secretaryAvatar" label="助理頭像">
            <AvatarSelector
              selectedAvatar={user.secretaryAvatar}
              setSelectedAvatar={(id) => setUser({ ...user, secretaryAvatar: id })}
              ownedAvatars={ownedAvatars}
              userPermissionLevel={userPermissionLevel}
            />
          </FormField>
        )}
        <Box pad="medium"></Box>
        <FormField name="preferredTheme" label="偏好的用戶介面主題" style={{ display: 'none' }}>
          <Select
            options={preferredTheme['preferred-theme'].map(option => option.name['zh-TW'])}
            value={preferredTheme['preferred-theme'].find(option => option.id === user.preferredTheme)?.name['zh-TW']}
            onChange={({ option }) => setUser({ ...user, preferredTheme: preferredTheme['preferred-theme'].find(opt => opt.name['zh-TW'] === option).id })}
          />          
        </FormField>
        <Box pad="medium" style={{ display: 'none' }}></Box>
        <FormField name="preferredBackground" label="偏好的聊天室背景" style={{ display: 'none' }}>
          <Select
            options={preferredBackground['preferred-background'].map(option => option.name['zh-TW'])}
            value={preferredBackground['preferred-background'].find(option => option.id === user.preferredBackground)?.name['zh-TW']}
            onChange={({ option }) => setUser({ ...user, preferredBackground: preferredBackground['preferred-background'].find(opt => opt.name['zh-TW'] === option).id })}
          />              
        </FormField>
        <Box pad="medium" style={{ display: 'none' }}></Box>
        <FormField name="preferredAvatarSize" label="偏好的頭像大小" style={{ display: 'none' }}>
          <Select
            options={preferredAatarSize['preferred-avatar-size'].map(option => option.name['zh-TW'])}
            value={preferredAatarSize['preferred-avatar-size'].find(option => option.id === user.preferredAvatarSize)?.name['zh-TW']}
            onChange={({ option }) => setUser({ ...user, preferredAvatarSize: preferredAatarSize['preferred-avatar-size'].find(opt => opt.name['zh-TW'] === option).id })}
          />             
        </FormField>
        <Box pad="medium" style={{ display: 'none' }}></Box>
        <FormField name="preferredLanguage" label="偏好的語言" style={{ display: 'none' }}>
          <Select
            options={preferredLanguage['preferred-language'].map(option => option.name['zh-TW'])}
            value={preferredLanguage['preferred-language'].find(option => option.id === user.preferredLanguage)?.name['zh-TW']}
            onChange={({ option }) => setUser({ ...user, preferredLanguage: preferredLanguage['preferred-language'].find(opt => opt.name['zh-TW'] === option).id })}
          />           
        </FormField>
        <Box pad="medium" style={{ display: 'none' }}></Box>
        <FormField name="preferredAIModel" label="聊天室的AI模型">
          <Select
            options={preferredAiModel['preferred-ai-model'].map(option => option.name['zh-TW'])}
            value={preferredAiModel['preferred-ai-model'].find(option => option.id === user.preferredAIModel)?.name['zh-TW']}
            onChange={({ option }) => setUser({ ...user, preferredAIModel: preferredAiModel['preferred-ai-model'].find(opt => opt.name['zh-TW'] === option).id })}
            disabled={user.permissionLevel >= 2} // 當用戶權限等級高於4時，禁用選擇器
          />              
        </FormField>        
        <Box pad="medium"></Box>        
        <Box direction="row" justify="between" margin={{ top: 'medium' }} pad="medium" >
          <Button type="submit" primary label="保存" style={{height: '50px', width: '120px'}}/>
          <Button label="返回" onClick={handleCancel} style={{height: '50px', width: '120px'}}/>
        </Box>      
        
        <Box style={{ 
          position: 'fixed', 
          bottom: '75px', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minWidth: '100px',            
          fontSize: '14px',          
          backgroundColor: '#f1f5ff',          
          padding: 0,
          borderRadius: '5px'
        }}>
          {message && <div>{message}</div>}
        </Box>
      </Form>
    </Box>
  );
};

export default ProfilePage;
