import { Collapsible, Button, Box, Image, Grid, Heading, Text } from 'grommet';
import React, { useState } from 'react';
import avatars from './avatar/avatars.json'; // 引入頭像數據
import avatarCategories from './avatar/avatar-category.json'; // 引入類別數據
import permissionLevels from './user/permission-level.json'; // 引入權限等級數據

const AvatarSelector = ({ selectedAvatar, setSelectedAvatar, ownedAvatars, userPermissionLevel }) => {
  const [showAvatars, setShowAvatars] = useState(false);

  // 獲取用戶的權限等級對象
  const userPermission = permissionLevels['permission-level'].find(level => level.id === userPermissionLevel);

  // 獲取用戶允許的頭像類別
  const allowedCategories = avatarCategories['avatar-category'].filter(category => userPermission['allowed-avatar-category'].includes(category.id));
  
  const selectedAvatarItem = avatars.avatars.find(avatar => avatar.id === selectedAvatar);

  return (
    <Box direction="column" align="left" justify="between" gap="small">      
      {selectedAvatar && (
        <Box align="center">
          <Image src={selectedAvatarItem ? selectedAvatarItem.path : 'defaultPath'} style={{ height: '240px', width: '240px', borderRadius: '50%'}} />
          <Box pad="small"></Box>
          <Text>{selectedAvatarItem ? selectedAvatarItem.name['zh-TW'] : 'defaultName'}</Text>
        </Box>
      )}
      <Box direction="column" align="center" justify="between" gap="small">
        <Button 
            onClick={() => setShowAvatars(!showAvatars)} 
            style={{ alignSelf: 'center', height: '50px', width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            primary >
            {selectedAvatar ? '更換頭像' : '選擇頭像'}
        </Button>

      </Box>
      <Collapsible open={showAvatars}>
        {allowedCategories.map((category) => (
          <Box key={category.id}>
            <Heading level="3">{category.name['zh-TW']}</Heading>
            <Grid columns='small' gap="small">              
              {avatars.avatars.filter(avatar => avatar.category_ids.includes(category.id) && ownedAvatars.includes(avatar.id)).map((avatar) => (
                <Box
                  key={avatar.id}
                  pad="small"
                  border={selectedAvatar === avatar.id ? { color: 'brand' } : undefined}
                  background={selectedAvatar === avatar.id ? 'light-6' : undefined} // 當前正在使用的頭像背景轉成灰色
                  onClick={() => {
                    setSelectedAvatar(avatar.id);
                    setShowAvatars(false);
                  }}
                  align="center" // 頭像名字置中
                >
                <Image src={avatar.path} style={{height: '120px', width: '120px', borderRadius: '50%'}} />
                <Box pad="small"></Box>
                  <Text>{avatar.name['zh-TW']}</Text>
                </Box>
              ))}
            </Grid>
          </Box>
        ))}
      </Collapsible>
    </Box>
  );
};

export default AvatarSelector;
